// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back {
  position: absolute;
  z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmailComposer/EmailComposer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".back {\r\n  position: absolute;\r\n  z-index: 100;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
