import React from "react";
import { FullPageChat } from "flowise-embed-react";
import { Link } from "react-router-dom";
import backIcon from "../../static/media/backIcon.svg";
import "./EmailComposer.css";

const EmailComposer = () => {
  return (
    <div className="EmailComposer">
      <div className="back">
        <Link to="/">
          <button>
            <img src={backIcon} alt="backbutton_icon"/>
            Back
          </button>
        </Link>
      </div>

      <FullPageChat
        chatflowid="54835b0e-df7b-4542-bc10-c23676dec465"
        apiHost="https://flowiseai-railway-production-8f7d.up.railway.app"
        theme={{
          chatWindow: {
            welcomeMessage: "Hello! This is custom welcome message",
            backgroundColor: "#ffffff",
            height: 555,
            width: 1275,
            fontSize: 16,
            poweredByTextColor: "#303235",
            botMessage: {
              backgroundColor: "#f7f8ff",
              textColor: "#303235",
              showAvatar: true,
              avatarSrc:
                "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/parroticon.png",
            },
            userMessage: {
              backgroundColor: "#3B81F6",
              textColor: "#ffffff",
              showAvatar: true,
              avatarSrc:
                "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png",
            },
            textInput: {
              placeholder: "Type your question",
              backgroundColor: "#ffffff",
              textColor: "#303235",
              sendButtonColor: "#3B81F6",
            },
          },
        }}
      />
    </div>
  );
};

export default EmailComposer;
