// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  width: 146px;
  height: 48px;
  padding: 12px, 24px, 12px, 24px;
  border-radius: 8px;
  background-color: rgb(59, 129, 246);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  border-style: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 100;
}

a {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChatAssistant/ChatAssistant.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,+BAA+B;EAC/B,kBAAkB;EAClB,mCAAmC;EACnC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qCAAqC;EACrC,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["button {\r\n  width: 146px;\r\n  height: 48px;\r\n  padding: 12px, 24px, 12px, 24px;\r\n  border-radius: 8px;\r\n  background-color: rgb(59, 129, 246);\r\n  font-size: 16px;\r\n  font-weight: 400;\r\n  line-height: 24px;\r\n  letter-spacing: 0.15000000596046448px;\r\n  border-style: none;\r\n  color: white;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  gap: 10px;\r\n  z-index: 100;\r\n}\r\n\r\na {\r\n  text-decoration: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
