import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import ChatAssistant from "./components/ChatAssistant/ChatAssistant";
import EmailComposer from "./components/EmailComposer/EmailComposer";
import LandingPage from "./components/LandingPage/LandingPage";
import ImageGenerator from "./components/ImageGenerator/ImageGenerator";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/chatAssistant" element={<ChatAssistant />} />
          <Route path="/EmailTriggerWebhook" element={<EmailComposer />} />
          <Route path="/ImageGenerator" element={<ImageGenerator />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
