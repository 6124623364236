import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../static/media/Logo.svg";
import "./LandingPage.css";

const LandingPage = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);

  const handleComponentSelect = (componentNumber) => {
    setSelectedComponent(componentNumber);
  };

  return (
    <div className="landing_page">
      <div className="left-section">
        <img class="logo" src={logo} alt="company_logo_icon" />
        <ul>
          <li>
            <Link to="/chatAssistant" onClick={() => handleComponentSelect(1)}>
              <button>QnA Chat Assistant</button>
            </Link>
          </li>
          <li>
            <Link
              to="/EmailTriggerWebhook"
              onClick={() => handleComponentSelect(2)}
            >
              <button>Email Trigger Webhook</button>
            </Link>
          </li>
          <li>
            <Link to="/ImageGenerator" onClick={() => handleComponentSelect(3)}>
              <button>Image Generator</button>
            </Link>
          </li>
          <li>
            <a
              href="https://flowiseai-railway-production-8f7d.up.railway.app/chatflows"
              target="_blank"
              rel="noreferrer"
            >
              <button>Create New</button>
            </a>
          </li>
        </ul>
      </div>
      <div className="right-section">
        <img
          width="390"
          height="355"
          alt="hero_image"
          src="https://flowiseai.com/_ipx/w_1080,q_75/%2F_next%2Fstatic%2Fmedia%2Fintegrations1.8181abd6.png?url=%2F_next%2Fstatic%2Fmedia%2Fintegrations1.8181abd6.png&w=1080&q=75"
        />
        <h1 className="heading_one">Build LLM Apps Easily</h1>
        <p>
          Open source UI visual tool to build your customized LLM orchestration
          flow & AI agents
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
